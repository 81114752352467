// Sidebar dark

.app-sidebar {
  &--overlay {
    &:after {
      content: '';
      background: $black;
      opacity: 0.45;
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & > * {
      z-index: 4;
    }
  }

  &--dark {
    //background: $sidebar-bg; 42b8e7
    background: #1da1f2; //dark mode sidebar color here
    &--content {
      background: $sidebar-content-bg;
    }

    perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y,
    .ps .ps__rail-y:hover {
      background-color: rgba($black, 0.05) !important;
    }

    .ps__thumb-y {
      background-color: rgba($black, 0.15) !important;
    }
  }
}
