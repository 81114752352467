// Slick slider

@use "sass:math";

.slick-slider {
  position: relative;
  display: block;

  user-select: none;
  touch-action: pan-y;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none;
  @include border-radius($border-radius-lg);

  & > div {
    outline: none;
    padding-right: 1px;

    & > div {
      outline: none;
    }
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging > * {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slider-right,
.slick-slider-left {
  .slick-arrow {
    display: none !important;
  }

  .slick-list.draggable {
    cursor: all-scroll;
  }
}

.slick-slider-left {
  .slick-dots {
    text-align: left;

    li {
      margin: 0 0 0 math.div($spacer, 1.5);
    }
  }
}

.slick-slider-left {
  .slick-dots {
    text-align: left;

    li {
      margin: 0 math.div($spacer, 1.5) 0 0;
    }
  }
}

// Theme

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  cursor: pointer;
  color: $primary;
  top: 50%;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: $box-shadow-sm;
  transition: $transition-base;
  transform: scale(1);
  @include border-radius(50px);
  background: $white;
  z-index: 6;
  overflow: hidden;
  opacity: 0;
  margin-top: -22px;
  left: 15px;

  & > * {
    height: 34px;
    width: 34px;
  }

  &:hover, &:focus {
    outline: none;
    transform: scale(1.15);
  }

  &:active {
    outline: none;
    transform: scale(1.05);
  }

  &.slick-disabled:before {
    opacity: 0.25;
  }
}

.slick-prev > * {
  left: -1px;
  position: relative;
}

.slick-next {
  left: auto;
  right: 15px;
}

.slick-slider {
  &:hover {

    .slick-next,
    .slick-prev {
      opacity: 1;
    }
  }
}

/* Dots */

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 8px;
  //position: relative;//absolute;
  bottom: 15px;
  left: 0;
  right: 0;

  li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 math.div($spacer, 3);
    cursor: pointer;
    transition: $transition-base;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 8px;
      width: 8px;
      padding: 0;
      background: $gray-600;
      cursor: pointer;
      position: relative;
      @include border-radius($border-radius);
      color: transparent;
      transition: $transition-base;
      outline: none !important;

    }

    &.slick-active {
      button {
        width: 26px;
        background: $primary;
      }
    }
  }
}

.slick-center {
  transform: scale(1.5);
}

// Light

.slider-dots-light {
  .slick-dots {
    li {
      button {
        background: rgba($white, 0.3);
      }

      &.slick-active {
        button {
          background: rgba($white, 1);
        }
      }
    }
  }
}

.slider-arrows-dark {
  .slick-prev,
  .slick-next {
    background: $primary;
    color:  $white;
    box-shadow: 0 0 0 0 transparent;

    &:hover {
      background: $white;
      color: $primary;
    }
  }
}

.slider-arrows-outside {
  overflow: visible !important;
  .slick-prev {
    left: -10px;
  }

  .slick-next {
    right: -10px;
  }
}

.slider-dots-outside {

  .slick-dots {
    bottom: -20px;
  }
}

